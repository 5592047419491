<template>
    <div class="view">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-mg-6">
                    <div class="view-title">{{ title }}</div>
                    <div 
                        class="view-description" 
                        v-for="(err, idx) in dataArray" 
                        :key="idx"
                        v-html="err"
                    />
                </div>>
                <div class="col-12 col-mg-6">
                    <img class="view-img" src="../../assets/no-power.svg">
                </div>>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        dataArray: {
            type: Array,
            default: () => [],
        }
    }
}
</script>

<style lang="scss" scoped>
.view {
    max-width: 800px;

    &-title {
        font-family: 'Lato';
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.813rem;
        text-transform: uppercase;
        color: #004488;
        margin: 0;
    }
    &-description {
        font-family: 'Lato';
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 150%;
        color: #696969;
        margin: 20px 0 0;

        ::v-deep {
            a, a:hover {
                color: #14AAF5
            }
        }
    }
    &-img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}
</style>